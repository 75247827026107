nav {
  h1 {
    color: $green;
    font-family: "Poppins-Bold";
  }
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 40px;
    li a {
      text-decoration: none;
      color: $textColor;
      font-size: 20px;
    }
    .pancake-links {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60%;
        height: 3px;
        border-radius: 2px;
        background-color: $green;
        transform: scale(0, 0);
      }
      &:hover::after {
        transform: scale(1, 1);
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        color: $textColor;
      }
    }
    .active {
      position: relative !important;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60%;
        height: 3px;
        border-radius: 2px;
        background-color: $green;
        transform: scale(1, 1);
      }
    }
  }
}

@media only screen and(max-width: 1200px) {
  nav .cjenik-items {
    display: none !important;
  }
}
