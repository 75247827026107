header {
  height: 100%;

  .content__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;

    .right {
      padding-right: 350px;

      @media only screen and (max-width: 1700px) {
        padding-right: 100px;
      }
      @media only screen and (max-width: 1500px) {
        width: 400px;
      }
    }

    h1 {
      font-family: "Poppins-Bold";
      font-size: $title;
      color: $textColor;
      width: 60%;

      span {
        color: $green;
      }
    }

    .para {
      font-size: $mediumFont;
      width: 80%;
      color: $textColor;
    }
  }
}
.button__wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}
.order {
  text-decoration: none;
  background-color: $green;
  display: flex;
  align-items: center;
  padding: 5px 30px;
  border-radius: 44px;
  -webkit-box-shadow: 0px 5px 8px 2px rgba(79, 185, 165, 0.66);
  box-shadow: 0px 5px 8px 2px rgba(79, 185, 165, 0.66);

  p {
    margin: 0;
    padding: 10px;
    color: $white;
    font-size: $mediumFont;
    width: max-content;
  }
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 20px 0;

  .links {
    ul {
      display: flex;
      align-items: center;
      list-style: none;

      li {
        margin-right: 30px;

        .main-links {
          text-decoration: none;
          color: $textColor;
          font-size: $mediumFont;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60%;
            height: 3px;
            border-radius: 2px;
            background-color: $green;
            transform: scale(0, 0);
          }
          &:hover::after {
            transform: scale(1, 1);
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            color: $textColor;
          }
        }
        .active {
          position: relative !important;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60%;
            height: 3px;
            border-radius: 2px;
            background-color: $green;
            transform: scale(1, 1);
          }
        }
      }
    }
  }
  .socials a {
    padding: 10px;

    svg path {
      fill: $white;
    }
  }
}
.find-btn {
  border: 1px solid $green;
  padding: 15px 45px;
  border-radius: 44px;
  color: $green;
  transition: 0.5s ease;

  &:hover {
    background-color: $green;
    color: $white;
  }
}

.overlay {
  height: 100%;
  width: 0%;
  position: fixed;
  right: 0;
  background-color: $textColor;
  top: 0;
  font-family: "Poppins";
  transition: 0.6s;
  z-index: 999;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    li {
      text-align: center;
      font-size: 30px;
      padding-bottom: 10px;
      width: max-content;
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;

      a {
        color: $white;
        text-decoration: none;
      }
      .find-btn-mobile {
        border: 1px solid $green;
        padding: 15px 45px;
        border-radius: 44px;
        color: $green;
        transition: 0.5s ease;
      }
    }
  }
}
.hamburger {
  display: none;
  z-index: 1000;
}

.background {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  z-index: -1;
}

.banana {
  position: absolute;
  bottom: 0;
  left: 25%;
}

.blueberry {
  position: absolute;
  left: 0;
  bottom: 20%;

  @media only screen and (max-width: 1600px) {
    bottom: 10%;
  }
}

.blueberries {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 1600px) {
  header .content__wrapper h1 {
    font-size: 40px !important;
  }
  header .content__wrapper .para {
    font-size: 22px;
  }
  header .content__wrapper .right img {
    width: 400px;
  }
  header nav .links ul li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1400px) {
  header .content__wrapper {
    padding-top: 0;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .background,
  .banana {
    display: none;
  }
  nav .socials a svg path {
    fill: $textColor;
  }
  .blueberries {
    bottom: 50%;
  }
  .blueberry {
    top: 40%;
  }
}

@media only screen and (max-width: 1200px) {
  nav .links {
    display: none;
  }
  nav .socials {
    display: none;
  }
  .hamburger {
    display: block;
  }
}

@media only screen and (max-width: 969px) {
  .blueberries {
    display: none;
  }
  header .content__wrapper .right {
    width: 90%;
    padding: 0;
  }
  header .content__wrapper .right img {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  header .content__wrapper h1 {
    width: 90%;
    font-size: 36px;
  }
  header .content__wrapper .para {
    width: 90%;
    font-size: 16px;
  }
  .blueberry {
    display: none;
  }
  /* .banana {
    display: block;
  } */
  .order {
    padding: 0px 15px;
  }
  .order p {
    font-size: 18px;
  }
}
