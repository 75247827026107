section.contact {
  height: 100%;
  width: 90%;
  margin-left: auto;

  .contact-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contact {
    width: 100%;
  }

  h1 {
    color: $textColor;
  }
}

.contact {
  padding-top: 100px;
}

.contact-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}

.contact-info h4 {
  color: var(--grayRed);
  font-family: "Poppins-Bold";
  padding-bottom: 20px;
  font-size: 36px;
}

.contact-info form {
  display: flex;
  flex-direction: column;
  align-items: start;
}

label {
  font-size: 16px;
  color: var(--white);
}

input,
textarea {
  padding: 20px;
  margin-bottom: 30px;
  width: 80%;
  border: 1px solid #828282;
  background: transparent;
  border-radius: 5px;
  color: var(--white);
}

.submit-btn {
  border: none;
  outline: none;
  width: 80%;
  background: #323232;
  color: $white;
  border-radius: 5px;
  padding: 20px;
}

@media only screen and (max-width: 1600px) {
  section.contact .contact-wrapper img {
    width: 800px;
  }
}

@media screen and (max-width: 1500px) {
  section.contact .contact-wrapper img {
    width: 100%;
  }
}

@media screen and (max-width: 1500px) {
  section.contact .contact-wrapper {
    flex-direction: column-reverse;
  }
  input,
  #textarea,
  .submit-btn {
    width: 85%;
  }
}
