section.bigMenu {
  width: 90%;
  margin: auto;
}
.main-title {
  color: $textColor;
  font-family: "Poppins-Bold";
  padding-top: 60px;
}
.card-menu-wrapper {
  padding-top: 50px;
}
.price-wrapper {
  border-bottom: 2px solid $textColor;
  padding-top: 60px;
  .price-tags {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: $green;
      font-family: "Poppins-Bold";
    }
  }
}

.selected-apis {
  display: inline-flex;
  align-items: center;
  list-style: none;
  gap: 30px;
  padding-left: 0;
  padding-top: 50px;

  li a {
    color: $textColor;
  }
}

@media only screen and(max-width: 850px) {
  .selected-apis {
    flex-direction: column;
    align-items: self-start;
  }
}
