@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Light";
  src: url("../assets/fonts/Poppins-Light.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

button {
  outline: none;
  border: none;
}

.i4ewOd-pzNkMb-haAclf {
  display: none !important;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: $green;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham8 .top {
  stroke-dasharray: 40 160;
}
.ham8 .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}
.ham8 .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}
.ham8.active .top {
  stroke-dashoffset: -64px;
}
.ham8.active .middle {
  //stroke-dashoffset: -20px;
  transform: rotate(90deg);
}
.ham8.active .bottom {
  stroke-dashoffset: -64px;
}

button {
  outline: none;
  border: none;
  background: none;
}

.toTopBtn {
  /* position: fixed;
  bottom: 20%;
  left: 5%;
  background-color: $green;
  padding: 50px;
  z-index: 1000; */

  background-color: $green;
  border: none;
  border-radius: 15%;
  color: white;
  cursor: pointer;
  font-size: 26px;
  height: 62px;
  width: 62px;
  transition: 0.3s ease;
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 1000;
}
