section.menu {
  width: 90%;
  margin: auto;
  height: 100%;

  h1 {
    color: $textColor;
  }

  .menu-selection {
    display: flex;
    align-items: center;
    padding-top: 50px;

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;

      li {
        margin-left: 20px;
      }

      a {
        position: relative;
        text-decoration: none;
        color: $textColor;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 50%;
          height: 3px;
          background-color: $textColor;
          border-radius: 3px;
          transform-origin: 0% 100%;
          transform: scale(0, 1);
          transition: all 0.3s ease-in-out;
        }
        &:active {
          color: $textColor !important;
        }
        &:hover::after {
          transform: scale(1, 1);
        }
      }

      .active-link {
        color: $green !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 50%;
          height: 3px;
          background-color: $green;
          border-radius: 3px;
          transform: scale(1, 1);
        }
        &:active {
          color: $green !important;
        }
      }
    }
  }
}

.menu-listed {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  background-image: url("../../assets/images/menu-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;

  .listed {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 60px;
  }

  .menu-second {
    padding-top: 30px;

    .pancake-card {
      -webkit-box-shadow: 2px 5px 15px 3px rgba(0, 0, 0, 0.25);
      box-shadow: 2px 5px 15px 3px rgba(0, 0, 0, 0.25);
    }
  }

  .pancake-card {
    background-color: $white;
    border-radius: 10px;
    width: 320px;

    .pancakeImg {
      border-radius: 10px;
      width: 320px;
      height: 295px;
    }
  }

  .pancake-card-info {
    padding: 10px;

    h1 {
      font-size: 24px;
      text-transform: capitalize;
      color: $textColor;
      font-family: "Poppins-Bold";
    }

    h2 {
      font-size: 18px;
    }

    h5 {
      font-size: 14px;
      font-family: "Poppins-Bold";
    }
  }
}

.menu-button {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
}

.tiny-slider {
  display: none;

  h1 {
    text-transform: capitalize;
    color: $textColor;
    font-family: "Poppins-Bold";
  }
  h5 {
    font-size: 14px;
    font-family: "Poppins-Bold";
  }
  .card-content-slider {
    background-color: $white;
    border-radius: 10px;
    width: 320px;
    border: 1px solid $green;
    margin: auto;
    margin-top: 50px;
  }

  img {
    border-radius: 10px;
    width: 320px;
    height: 295px;
  }
  .content {
    padding: 10px;
  }
}

.buttons {
  display: none;
  float: right;
  padding: 50px;

  .next-button {
    padding-left: 20px;
  }

  svg {
    fill: $textColor;

    &:hover {
      fill: $green;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .menu-listed .pancake-card:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 726px) {
  .menu-listed {
    display: none;
  }
  .tiny-slider,
  .buttons {
    display: block;
  }
  section.menu {
    height: 100%;
    padding-bottom: 50px;
  }
  .menu-button {
    padding: 0;
  }
}
