section.map {
  width: 90%;
  margin: auto;
  padding-bottom: 100px;
  padding-top: 100px;

  iframe {
    border-radius: 10px;
  }
}

.map-container {
  height: 400px;
  width: 100%;
  margin: auto;
  border-radius: 10px;
}

/* .sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
} */
