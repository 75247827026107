section.about {
  position: relative;
  height: 100%;
  padding: 450px 0 50px 0;

  .about-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 100px;
  }

  .about-content {
    width: 50%;
    z-index: 100;

    h3 {
      font-size: 48px;
      color: $textColor;
      font-family: "Poppins Light";
    }

    h1 {
      font-size: 48px;
      font-family: "Poppins-Bold";
      color: $green;
    }

    p {
      width: 60%;
      color: $grayPara;
      padding: 20px 0;
    }

    a {
      color: $white;
      background-color: $green;
      border-radius: 40px;
      padding: 10px 40px;
      text-decoration: none;
    }
  }

  .greenWave {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (max-width: 1400px) {
  section.about {
    padding: 50px 0 50px 0;
  }
}

@media only screen and (max-width: 1428px) {
  section.about .about-content p {
    margin-right: 0;
  }
  section.about .about-content {
    margin-left: 10px;
  }
  .coffee-wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 1200px) {
  section.about .about-wrapper {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    padding-top: 0;
  }
  .coffee-wrapper {
    width: 60%;
  }
}

@media only screen and (max-width: 990px) {
  section.about .greenWave {
    width: 100%;
  }
  .coffee-wrapper img {
    width: 100%;
  }
  section.about .about-content h1,
  section.about .about-content h3 {
    font-size: 36px;
  }
  section.about .about-content {
    width: 100%;
    padding-top: 100px;
  }
}
