footer.footer {
  position: relative;
  padding-top: 100px;

  .img-footer-2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .footer-wrapper {
    display: inline-flex;
    justify-content: space-around;
    width: 80%;
    margin: auto;

    h4 {
      color: $textColor;
    }

    li,
    a {
      color: $grayPara;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 986px) {
  footer.footer .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 500px) {
  footer.footer .img-footer-2 {
    display: none;
  }
  footer.footer .footer-wrapper {
    width: 100%;
  }
}
